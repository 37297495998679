import React, { useEffect, useState } from "react";
import { useStateContext } from "contexts/ContextProvider";
import ScreenshotCustomization from "assets/screenshots/dashboard-customization.png";
import ScreenshotNotificationSeeMore from "assets/screenshots/notifications-seemore.png";
import ScreenshotNotificationDelete from "assets/screenshots/notifications-delete.png";
import ScreenshotWidgetOptions from "assets/screenshots/widgets-options.png";
import ScreenshotWidgetResize from "assets/screenshots/widgets-resize.png";
import ScreenshotAnalyticsSelect from "assets/screenshots/analytics-select.png";
import ScreenshotLogin from "assets/screenshots/login.png";
import ScreenshotEventsTable from "assets/screenshots/events-table.png";
import ScreenshotAppearenceDarkmode from "assets/screenshots/appearence-darkmode.png";
import { Combobox } from "components/ui/combobox";
import { Button } from "components/ui/button";
import { availableWidgets } from "data/chartData/frontLayout";
import { Alert, AlertTitle, AlertDescription } from "components/ui/alert";
import { Icon } from "@iconify/react";
import { ReactComponent as AdjustmentsIcon } from "icons/adjustments.svg";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as InboxIcon } from "icons/inbox.svg";

const Help = () => {
  const [selectedSetting, setSelectedSetting] = useState("");

  const renderContent = () => {
    switch (selectedSetting) {
      case "dashboard":
        return (
          <React.Fragment key="dashboard">
            <p>
              El panel de control proporciona una visión general del estado de
              la red y es personalizable. Dependiente de tu rol, puedes elegir
              qué mostrar y cómo, creando una experiencia adaptada a tus
              necesidades. El panel de control siempre se actualiza en tiempo
              real, eliminando la necesidad de actualizar la página manualmente.
            </p>

            <h3>Personalización del panel de control</h3>
            <p>
              Al hacer clic en 
              <span className="inline-block translate-y-1 mx-1">
              <Button
                variant="ghost"
                className={`text-gray-500 dark:text-gray-200
                 pl-0.5 pr-2 h-7 items-center flex hover:text-primary-600 dark:hover:text-primary-300`}>
                <EditIcon className="size-5 p-1" />
                <span className="text-xs hidden md:inline-flex">
                  Modificar vista
                </span>
              </Button>
                  </span>
               en la esquina superior derecha,
              puedes gestionar el contenido del tablero. Esto te permite añadir
              nuevos widgets, cada uno ofrece opciones y ventajas diferentes.
              Puedes cambiar el tamaño de los widgets existentes arrastrando las
              esquinas resaltadas en blanco y reposicionar los widgets
              archivados haciendo clic en el botón de "opciones" que aparece en
              la esquina superior derecha de cualquier widget cuando pasas el
              cursor sobre él.
            </p>
            <img
              src={ScreenshotCustomization}
              className="my-2 shadow-xl rounded-lg max-w-80 mx-auto "
            />
            <Button className="w-fit" onClick={() => setSelectedSetting("widgets")}>
              Conocer los widgets
            </Button>

            {/* // TODO: Add section about saving and restoring dashboard layouts */}

          </React.Fragment>
        );
      case "customization":
        return (
          <>
            <p>
              You can customize things like the brightness of the interface from
              the "appearance" section, accessible by pressing on your profile
              icon and then on the settings icon in the popup.
            </p>
            <img
              src={ScreenshotAppearenceDarkmode}
              className="my-2 shadow-xl rounded-lg max-w-80 mx-auto "></img>
          </>
        );

      case "appearance":
        return (
          <>
            <p>
              En la sección de configuración, accesible haciendo clic en el
              icono de tu perfil y luego en el icono de configuración en el
              popup, puedes personalizar varios elementos de la interfaz.
            </p>
            <img
              src={ScreenshotAppearenceDarkmode}
              className="my-2 shadow-xl rounded-lg max-w-80 mx-auto "></img>
            <p>
              Por ejemplo, bajo la sección "Apariencia", tienes la opción de
              ajustar el brillo seleccionando entre los modos "Claro", "Oscuro"
              o "Automático". Además, puedes modificar el tamaño de la fuente a
              tu preferencia usando el deslizador proporcionado.
            </p>
            <p>
              El diseño de la página principal también puede ser personalizado,
              permitiéndote elegir entre diseños "Simple", "Ordenado",
              "Complejo" y "Personalizado". Esta flexibilidad asegura que puedas
              adaptar el tablero para satisfacer tus necesidades y preferencias
              específicas.
            </p>
          </>
        );
      case "widgets":
        return (
          <>
            <h3>Widgets disponibles</h3>
            <ul className="flex flex-col gap-y-2">
              {availableWidgets.map((widget) => (
          <li className="flex flex-col gap-y-1" key={widget.id}>
            <div className=" gap-2 flex flex-row">
            <div className="size-8">
              {widget.icon}
            </div>
              <h4>{widget.title}</h4>
            </div>
              <p>{widget.longDescription}</p>
          </li>
              ))}
            </ul>
            <Alert >
            <Icon icon="ion:alert" className="size-4" />
            <AlertTitle>Importante</AlertTitle>
            <AlertDescription>
              Algunos widgets requieren alguna configuración (por ejemplo, establecer tu ciudad) antes de poder usarlos. En ese caso, te lo indicarán una vez que los coloques.
            </AlertDescription>
            </Alert>


            <h3>Almacenamiento de widgets</h3>
            <p>
              Los widgets se pueden almacenar para usarlos más tarde y mantienen la configuracion. Simplemente haz clic en el botón 
              <span className="mx-1">

              <Button
          className="h-auto p-1 opacity-100 dark:text-gray-600 text-gray-200  hover:text-gray-800 dark:hover:text-gray-100 rounded-full "
          variant="outline">
          <AdjustmentsIcon className="size-3.5 text-current" />
        </Button>
              </span>
              que aparece en la esquina superior derecha de un widget cuando pasas el cursor sobre él, luego selecciona "almacenar".
            </p>
            <img
              src={ScreenshotWidgetOptions}
              className="my-2 shadow-xl rounded-lg max-w-80 mx-auto "
            />
            <p>
              Los widgets se pueden redimensionar cuando estás en el modo de edición activado al presionar el botón "Modificar vista".
            </p>
            <img
              src={ScreenshotWidgetResize}
              className="my-2 shadow-xl rounded-lg max-w-80 mx-auto "
            />
            {/* [TODO: Ajustar datos del widget y vincular sensores] */}
          </>
        );
      case "notifications":
        return (
          <>
            <p>
              Las notificaciones te mantienen actualizado sobre los datos en tiempo real. Para accederlos, toca el icono
              <span className="mx-1 inline-block">
              <Button

          className="relative size-8 p-1 sm:p-1 md:p-2 rounded-full after:content-[''] after:transition-colors after:-inset-0.5 after:rounded-full after:z-0 after:hover:bg-gray-100 dark:after:hover:bg-gray-800 after:absolute hover:text-primary-500 transition-colors items-center justify-center flex"
          variant="primary"
        >
          <InboxIcon className="size-full dark:text-gray-50 z-10" />

            <span className="absolute z-10 -top-1 sm:top-1 -right-1 sm:right-1 w-3 h-3 bg-red-500 text-xxs text-gray-50 dark:text-gray-900 rounded-full flex items-center justify-center">
              3
            </span>

        </Button>
          </span>
               en la parte superior derecha, al lado de tu perfil. Para expandir una notificación, haz clic en "ver" cuando pases el cursor sobre ella. 
               {/* Puedes eliminar una notificación haciendo clic en el icono de "Basura" en el lado derecho. */}
            </p>
              <img
                src={ScreenshotNotificationSeeMore}
                className="my-2 shadow-xl rounded-lg max-w-80 mx-auto "
              />
              <img
                src={ScreenshotNotificationDelete}
                className="my-2 shadow-xl rounded-lg max-w-80 mx-auto "
              />
            <p>
              También puedes ver o eliminar todas las notificaciones o ver las que se han eliminado previamente haciendo clic en los botones respectivos en la parte inferior del pop-up.
            </p>
          </>
        );
      case "privacy":
        return <div>Privacidad</div>;
      case "analytics":
        return (
          <>
            <p>
              To access the analytics page you can simply press on the
              "analytics" tab from the sidebar. Once you're in, just select the
              devices you want to analyze from the list y ticking the box to
              their left: this will make the info apper in the table to the
              side. When selecting multiple devices the table can be used to
              make comparisons between them. At the same time, in the analytics
              page, you also have access to an interactive map where you can
              choose a device directly and see the info in a pop-up.
            </p>
            <img
              src={ScreenshotAnalyticsSelect}
              className="my-2 shadow-xl rounded-lg max-w-80 mx-auto "
            />
            [TODO: Add types of reports available] [TODO: Add customizing
            reports and analytics]
          </>
        );
      case "events":
        return (
          <>
            <p>
              You can see and manage all events in the events tab. Events have
              multiple attributes:
              <ul className="list-disc">
                <li>Mensaje</li>
                <li>Urgencia</li>
                <li>Estado</li>
                <li>Ubicación</li>
                <li>Asignado</li>
                <li>Actualizado</li>
              </ul>
              All these appear in each event's row in the events table. You can
              also manage events and see them in the calendar.
              <p className="border-red-500 border-2 rounded-sm p-2">
                {" "}
                {/* TODO: Better styling */}
                <b>N.B.:</b> The table can scroll in both directions (X & Y
                axis).
              </p>
              <img
                src={ScreenshotEventsTable}
                className="my-2 shadow-xl rounded-lg max-w-80 mx-auto "
              />
            </p>
          </>
        );
      case "community":
        return (
          <>
            <p>
              En esta pestaña puedes ver una lista o una vista en cuadrícula, compuesta por todas las personas de la comunidad. Junto a sus nombres completos, también puedes ver sus nombres de usuario, números de teléfono y otros detalles útiles. Al hacer clic en "Ver perfil" puedes ver más detalles.
            </p>
            <p>
              Como Gestor, también puedes gestionar a los usuarios. Puedes agregar nuevos usuarios, verificar nuevos usuarios y editar o eliminar usuarios existentes.
            </p>
          </>
        );
      case "feedback":
        return (
          <>
            <p>
            Si quieres decirnos algo sobre nuestro producto, simplemente haz clic en tu icono de perfil y luego en "Feedback". Tu opinión es crucial para que podamos mejorar y adaptar nuestros servicios. El proceso es rápido y sencillo, asegurando que tu voz sea escuchada y ayude a dar forma al futuro de nuestro producto. Gracias por compartir tus ideas.            
            </p>
          </>
        );
      // Handle other cases
      default:
        // FAQ
        return (
          <>
            <h2>Preguntas freuquentes</h2>
            <h3>¿Qué datos se almacenan?</h3>
            <p>
              Se almacenan los datos de los sensores, los eventos y las
              notificaciones. Todos los datos son anónimos y no se almacena
              información personal.
            </p>
          </>
        );
    }
  };

  // Some inspiration
  // - Dashboard
  // → Dashboard usage
  // → add a widget
  // → Adjust the data displayed
  // → Move the widgets
  // → Widget Storage
  // → Iconografia
  //  - Analytics
  //  - FAQ
  // → Which data is stored? xxx
  // → Where do i adjust the font size?
  const sections = [
    {
      value: "dashboard",
      label: "Panel de Control",
    },
    // {
    //   value: "customization",
    //   label: "Personalización",
    // },
    {
      value: "widgets",
      label: "Widgets",
    },
    {
      value: "analytics",
      label: "Análisis y Reportes",
    },
    {
      value: "community",
      label: "Comunidad",
    },
    {
      value: "events",
      label: "Eventos",
    },
    {
      value: "appearance",
      label: "Apariencia",
    },
    {
      value: "notifications",
      label: "Notificaciones",
    },
    {
      value: "privacy",
      label: "Privacidad",
    },
    {
      value: "feedback",
      label: "Retroalimentación",
    },

    // TODO: Remove once switch on userProfile.role inside component works
  ];

  return (
    <div className="flex gap-4  relative h-full overflow-clip divide-y-2 flex-col">
      <Combobox sections={sections} onSelectionChange={setSelectedSetting} />

      <div className="w-full flex flex-col gap-y-2 pb-4 custom-scrollbar overflow-y-auto h-full dark:text-white">
        <h2 className="bg-gradient-to-b dark:from-gray-950 dark:via-gray-950 from-white via-white via-70% to-transparent pt-3 pb-5 top-0 sticky">
          {sections.find((section) => section.value === selectedSetting)?.label}
        </h2>

        {renderContent()}
      </div>
    </div>
  );
};

export default Help;
