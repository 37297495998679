import {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { eventData } from "data/chartData/eventData";
import unifiedData from "data/chartData/unifiedData";
import { queueData } from "data/chartData/queueData";
import axios from "axios";
import { toast } from "sonner";
import { format, addMinutes, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { eventOptions } from "data/translationArrays";

const EventContext = createContext();

const colorPalette = {
  event: "#0073b7",
  notification: "#ff851b",
  queue: "#00a65a",
};
const timeZone = "Europe/Madrid";

const EventsProvider = ({ children }) => {
  const [events, setEvents] = useState([]); //
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState("timeGridWeek");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isEditingEvent, setIsEditingEvent] = useState(false);

  const [userData, setUserData] = useState([]);
  //const { userData, setUserData } = useStateContext();

  const transformUserData = (data) => {
    return data.map((user) => ({
      id: user.user_id || "",
      username: user.user_name || "",
      fullname: user.first_name + " " + user.last_name || "",
      role: user.role_names || "",
    }));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/manageUsers?action=getUsers"
      );
      if (response.data.body) {
        const responseBody = JSON.parse(response.data.body);
        const transformedData = transformUserData(responseBody);

        setUserData(transformedData);
      } else {
        console.error("No body in response");
      }
    } catch (error) {
      console.error("Error fetching data from AWS Lambda:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchEventsFromDB = async () => {
    try {
      const response = await axios.get(
        "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/events/getEvents?type=all"
      );

      if (response.status >= 200 && response.status < 300) {
        const responseBody = JSON.parse(response.data.body);
        return responseBody;
      } else {
        console.error("No body in response");
        return [];
      }
    } catch (error) {
      console.error("Error fetching events from AWS Lambda:", error);
      return [];
    }
  };

  useEffect(() => {
    const initializeEvents = async () => {
      const storedEvents = localStorage.getItem("events");

      if (storedEvents) {
        setEvents(JSON.parse(storedEvents));
        //console.log("eventHandlers inside if storedEvents");
      } else {
        const dbEvents = await fetchEventsFromDB();
        const mergedEvents = dbEvents.map((event) => {
          const start = toZonedTime(event.start, timeZone);
          let end = toZonedTime(event.end, timeZone);

          if (!event.end && event.duration) {
            end = addMinutes(start, event.duration);
          }

          return {
            ...event,

            title:
              event.title ||
              userData.find((user) => user.id === event.user_id)?.fullname,
            user_id: event.user_id,
            userName:
              userData.find((user) => user.id === event.user_id)?.username ||
              "",
            userRole:
              userData.find((user) => user.id === event.user_id)?.role || "",
            start: start,
            duration: event.duration || null,
            end: end,
            urgency: event.urgency || null,
            assigned: event.assigned || null, // Incluir lógica para mostrar nombre de usuario asignado
            comments: event.comments || null,
            type: event.type || "event",
            backgroundColor: colorPalette[event.type] || colorPalette.event,
            parcel_order: event.parcel_order || null,
          };
        });

        setEvents(mergedEvents);

        localStorage.setItem("events", JSON.stringify(mergedEvents));
      }
      /* } else {
        const mergedEvents = [];

        eventData.forEach(event => {
          const start = event.start instanceof Date ? event.start : new Date(event.start);
          let end = event.end instanceof Date ? event.end : new Date(event.end);
          if (!event.end && event.duration) {
            end = new Date(start.getTime() + event.duration * 60000); // Convert duration from minutes to milliseconds
          }

          mergedEvents.push({
            ...event,
            //id: event.event_id,
            title: event.title || userData.find(user => user.id === event.user_id)?.fullname,
            user_id: event.user_id,
            userName: userData.find(user => user.id === event.user_id)?.username || "",
            userRole: userData.find(user => user.id === event.user_id)?.role || "",
            start: start,
            duration: event.duration || null,
            end: end,
            urgency: event.urgency || null,
            assigned: event.assigned || null,
            comments: event.comments || null,
            type: event.type || 'event',
            backgroundColor: colorPalette[event.type] || colorPalette.event,
          });
        });

        setEvents(mergedEvents);
        console.log("eventHandlers events", mergedEvents);
        localStorage.setItem('events', JSON.stringify(mergedEvents));
      } */
    };

    if (userData.length > 0) {
      initializeEvents();
    }
  }, [userData]);

  useEffect(() => {
    /* console.log("eventHandlers events: ", events) */
    if (events.length > 0) {
      localStorage.setItem("events", JSON.stringify(events));
    }
  }, [events]);

  const handleEventClick = useCallback((clickInfo) => {
    setCurrentEvent(clickInfo.event);
    setIsPopoverOpen(true);
  }, []);

  const handleUpdateEvent = (updatedEvent) => {
    // TODO ONLY ACCEPT EVENT_ID or ID
    const updatedEvents = events.map((localEvent) =>
      localEvent.event_id === updatedEvent.event_id ||
      localEvent.event_id === updatedEvent.id
        ? { ...localEvent, ...updatedEvent }
        : localEvent
    );

    setEvents(updatedEvents);
  };

  const handleAddTask = async (newTask) => {
    /* console.log("EventHandlers handleAddTask newTask: ", newTask); */
    setIsEditingEvent(true);
    const parsedUserId = JSON.parse(sessionStorage.getItem("userId"));

    //Extract and format the variables
    const userId = parsedUserId;
    const title = newTask.nameTask;
    const start = format(new Date(newTask.start), "yyyy-MM-dd HH:mm:ss.SSSSSS");
    const end = format(new Date(newTask.end), "yyyy-MM-dd HH:mm:ss.SSSSSS");
    //const duration = (new Date(newTask.end) - new Date(newTask.start)) / 1000; // Duration in seconds
    const urgency = newTask.priority;
    const assigned = newTask.assignedUser || ""; // Handle empty assignedUser
    const comments = newTask.comments || ""; // Assuming comments might be in newTask
    const type = newTask.type;
    const parcela = newTask.parcela || null;

    const jsonPayload = {
      userId,
      title,
      start,
      end,
      urgency,
      assigned,
      comments,
      type,
      parcela,
      queryType: "normal",
    };

    try {
      const response = await axios.post(
        `https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/events/createEvent`,
        jsonPayload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      /* console.log("EventHandlers handleAddTask createEvent response: ", response); */
      const responseBody = response.data;

      if (responseBody.statusCode >= 200 && responseBody.statusCode < 300) {
        const parsedBody = JSON.parse(responseBody.body);
        /* console.log("Community Parsed response body:", parsedBody); */
        toast.success(parsedBody.message);
      } else {
        let errorMessage = "Error durante creación del evento";
        switch (responseBody.statusCode) {
          case 400:
            errorMessage = responseBody.message;
            break;
          case 401:
            errorMessage = responseBody.message;
            break;
          case 500:
            errorMessage = responseBody.message;
            break;
          default:
            errorMessage = `Error inesperado: ${response.statusText}`;
        }
        toast.error(errorMessage);
      }

      const updatedEvents = [
        ...events,
        {
          id: uuidv4(),
          title: newTask.nameTask,
          start: new Date(newTask.start).toISOString(),
          end: new Date(newTask.end).toISOString(),
          urgency: newTask.urgency || "Low",
          assigned: newTask.assigned || [],
          type: newTask.type || "event",
          backgroundColor: colorPalette[newTask.type],
        },
      ];
      setEvents(updatedEvents);
      setIsEditingEvent(false);
    } catch (error) {
      console.error("Error adding task: ", error);
      toast.error("Error adding task");
    }
  }; //End handleAddTask

  const [fetchedData, setFetchedData] = useState([]);

  const transformData = (events) => {
    return events.map((event) => ({
      id: event.event_id,
      message: event.title,
      location: event.parcela || event.location,
      updated: event.start,
      urgency: event.urgency ? parseInt(event.urgency, 10) : null,
      assigned: event.assigned,
      type:
        eventOptions.find((option) => option.value === event.type)?.label ||
        event.type,
      backgroundColor: colorPalette[event.type] || colorPalette.event,
    }));
  };

  useEffect(() => {
    const fetchAndSetData = async () => {
      const eventsFromDB = await fetchEventsFromDB();

      const transformedData = transformData(eventsFromDB);

      setFetchedData(transformedData);
    };

    fetchAndSetData();
  }, [setIsEditingEvent]);

  return (
    <EventContext.Provider
      value={{
        events,
        setEvents,
        isDropdownOpen,
        setIsDropdownOpen,
        showEditModal,
        setShowEditModal,
        currentEvent,
        setCurrentEvent,
        currentView,
        setCurrentView,
        currentDate,
        setCurrentDate,
        handleEventClick,
        handleUpdateEvent,
        isPopoverOpen,
        setIsPopoverOpen,
        handleAddTask,
        fetchEventsFromDB,
        isEditingEvent,
        setIsEditingEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
export { EventsProvider };

export const useEventHandlers = () => useContext(EventContext);
