import { Model } from "./huerta3D";
import { Suspense } from "react";
import { Canvas, useFrame, extend } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
// import { Stats } from "@react-three/drei";
import { useRef, useState, useEffect, useMemo } from "react";
import * as THREE from "three";
import { Line } from '@react-three/drei';

import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import robotoFont from "three/examples/fonts/helvetiker_regular.typeface.json";
import PointerWithLabel from "./PointerWithLabel";
// Extend R3F with TextGeometry
extend({ TextGeometry });

const Scene = ({ data }) => {

  // console.log("Scene: data", data);
  const items = useMemo(() => {
    return data.map(item => {
      // const lastMeasurement = item.data[item.measurements.length - 1];
      const pressure = item.data.find(d => d.category === 'pressure')?.measurements.slice(-1)[0]?.value;
      const temperature = item.data.find(d => d.category === 'temperature')?.measurements.slice(-1)[0]?.value;
      const flow = item.data.find(d => d.category === 'flow')?.measurements.slice(-1)[0]?.value;  
      return {
        title: item.title,
        pressure,
        temperature,
        flow,
        coordX: item.location.coordinates3d[0],
        coordY: item.location.coordinates3d[1]
      };
    });
  }, [data]);
  return (
    <div className="h-full bg-gray-100">
      <Canvas>
        {/* <axesHelper args={[5]} /> */}
        <Suspense fallback={null}>
          <group position={[0, -12, 0]} rotation={[(0 * Math.PI) / 2, (0 * Math.PI) / 2, (0 * Math.PI) / 2]}>

          <Environment preset="sunset" />
          {items.map((item, index) => (
            <PointerWithLabel
              key={index}
              coordX={item.coordX}
              coordY={item.coordY}
              title={item.title}
              pressure={item.pressure}
              temperature={item.temperature}
              flow={item.flow}
            />
          ))}
             {/* <mesh position={[-6, -3, 20 / 2]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh>
             <mesh position={[-6, 19, 20 / 2]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh>
             <mesh position={[5, -3, 20 / 2]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh>
             <mesh position={[5, -3, 10]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh> */}

            {/* 1 */}
             {/* <mesh position={[1, 17.5, 10]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh>
             <mesh position={[0.8, 17, 10]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh>
             <mesh position={[0.3, 14.25, 10]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh>
             <mesh position={[-0.35, 12.2, 10]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh>
             <mesh position={[-0.4, 11.9, 10]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh>
             <mesh position={[-1.35, 9.4, 10]} rotation={[Math.PI / 2, 0, 0]}>
              <cylinderGeometry args={[0.01, 0.01, 20, 32]} />
              <meshStandardMaterial color="red" />
            </mesh> */}
          <Model position={[0, 0, 4.5]} rotation={[0,0, (3 * Math.PI) / 2]} />
          </group>

        </Suspense>
        <OrbitControls minDistance={2} />
      </Canvas>
    </div>
  );
};

export default Scene;