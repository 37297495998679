import { subDays, subHours, subMinutes } from 'date-fns';

const generateTimeframeBounds = (timeframe, referenceDate = new Date()) => {
  let start, end;
  // console.log("gTfB: Timeframe:", timeframe);
  // console.log("gTfB: Reference Date:", referenceDate);
  switch (timeframe) {
    case 'latest':
      start = null;
      end = null;
      break;
    case 'last10Minutes':
      start = subMinutes(referenceDate, 10);
      end = referenceDate;
      break;
    case 'lastHour':
      start = subHours(referenceDate, 1);
      end = referenceDate;
      break;
    case 'last3Hours':
      start = subHours(referenceDate, 3);
      end = referenceDate;
      break;
    case 'last24Hours':
      start = subHours(referenceDate, 24);
      end = referenceDate;
      break;
    case 'last3Days':
      start = subDays(referenceDate, 3);
      end = referenceDate;
      break;
    case 'last7Days':
      start = subDays(referenceDate, 7);
      end = referenceDate;
      break;
    case 'last30Days':
      start = subDays(referenceDate, 30);
      end = referenceDate;
      break;
      case 'last60Days':
      start = subDays(referenceDate, 60);
      end = referenceDate;
      break;
    case 'custom': // Assuming 'custom' has a different handling mechanism
      // Implement custom logic here
      break;
    default:
      console.error("Unknown timeframe:", timeframe);
  }
  // console.log("gTfB: Start:", start);
  // console.log("gTfB: End:", end);
  return { start, end };
};

export default generateTimeframeBounds;
