export const sensorCoordinates = {
  "29": {
    title: "Sensor 1",
    // "title": "Acequia Fabiana - Pozo del Agricultor",
    "coordx": 726665.02,
    "coordy": 4369676.67,
    "coordx3d": 1,
    "coordy3d": 17.5,
  },
  "63": {
    title: "Sensor 2",
    // "title": "Acequia Fabiana",
    "coordx": 726696.58,
    "coordy": 4369663.44,
    "coordx3d": 0.8,
    "coordy3d": 17,
  },
  "142": {
    title: "Sensor 3",
    // "title": "Partidor C/ Maripepa Colomer",
    "coordx": 726766.5,
    "coordy": 4369627.38,
    "coordx3d": 0.3,
    "coordy3d": 14.25,
  },
  "261": {
    title: "Sensor 4",
    // "title": "Entrada Alqueria Ros a/Compuerta ",
    "coordx": 726862.67,
    "coordy": 4369557.6,
    "coordx3d": -0.35,
    "coordy3d": 12.2,
  },
  "263": {
    title: "Sensor 5",
    // "title": "Entrada Alqueria Ros d/Compuerta",
    "coordx": 726865.6,
    "coordy": 4369556.49,
    "coordx3d": -0.4,
    "coordy3d": 11.9,
  },
  "371": {
    title: "Sensor 6",
    // "title": "Entrada Casa Conole",
    "coordx": 726955.12,
    "coordy": 4369493.74,
    "coordx3d": -1.35,
    "coordy3d": 9.4,
  },
  "375": {
    title: "Sensor 7",
    // "title": "Entrada Casa Conole",
    "coordx": 726955.12,
    "coordy": 4369493.74,
    "coordx3d": -1.35,
    "coordy3d": 9.4,
  },
  "460": {
    title: "Sensor 8",
    // "title": "Acequia Fabiana - Pozo del Agricultor",
    "coordx": 726665.02,
    "coordy": 4369676.67,
    "coordx3d": 1,
    "coordy3d": 17.5,
  },
  "550": {
    title: "Sensor 9",
    // "title": "Acequia Fabiana",
    "coordx": 726696.58,
    "coordy": 4369663.44,
    "coordx3d": 0.8,
    "coordy3d": 17,
  },
  "700": {
    title: "Sensor 10",
    // "title": "Partidor C/ Maripepa Colomer",
    "coordx": 726766.5,
    "coordy": 4369627.38,
    "coordx3d": 0.3,
    "coordy3d": 14.25,
  },
  "800": {
    title: "Sensor 11",
    // "title": "Entrada Alqueria Ros",
    "coordx": 726862.67,
    "coordy": 4369557.6,
    "coordx3d": -0.35,
    "coordy3d": 12.2,
  },
  "980": {
    title: "Sensor 12",
    // "title": "Entrada Alqueria Ros",
    "coordx": 726865.6,
    "coordy": 4369556.49,
    "coordx3d": -0.4,
    "coordy3d": 11.9,
  },
  "1100": {
    title: "Sensor 13",
    // "title": "Entrada Casa Conole",
    "coordx": 726955.12,
    "coordy": 4369493.74,
    "coordx3d": -1.35,
    "coordy3d": 9.4,
  },
  "240": {
    title: "Sensor 14",
    // "title": "Acequia Fabiana - Pozo del Agricultor",
    "coordx": 726665.02,
    "coordy": 4369676.67,
    "coordx3d": 1,
    "coordy3d": 17.5,
  },
  "70": {
    title: "Sensor 15",
    // "title": "Acequia Fabiana",
    "coordx": 726696.58,
    "coordy": 4369663.44,
    "coordx3d": 0.8,
    "coordy3d": 17,
  },
  "80": {
    title: "Sensor 16",
    // "title": "Partidor C/ Maripepa Colomer",
    "coordx": 726766.5,
    "coordy": 4369627.38,
    "coordx3d": 0.3,
    "coordy3d": 14.25,
  },
  "1": {
    title: "Sensor 17",
  // "title": "Entrada Casa Conole",
    "coordx": 726955.12,
    "coordy": 4369493.74,
    "coordx3d": -1.35,
    "coordy3d": 9.4,
  },
  // 1, 29, 30, 63, 70, 142, 240, 261, 263, 371, 375, 460, 550, 700, 800, 980, 1100
};