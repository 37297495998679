import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogFooter,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "components/ui/select";
import { formatDateTimeRange } from "lib/timeFormattingFunctions";
import { DateTimePickerForm } from "components/ui/time-picker/date-time-picker-form";
import { Checkbox } from "components/ui/checkbox";
import { ButtonGroup } from "@mui/material";
import { maintenanceItemsWithLabels } from "data/translationArrays";
import axios from "axios";

const CustomPopover = ({
  isOpen,
  setIsOpen,
  onClose,
  event,
  containerId,
  onSave,
  eventOptions,
  setIsEditingEvent,
}) => {
  const [localEvent, setLocalEvent] = useState(
    event
      ? { ...event }
      : {
          title: "",
          type: "",
          start: null,
          end: null,
          // status: "",
          urgency: "",
          assigned: "",
          // city: "",
          // location: "",
          // coordinates: [],
          recurrence: false,
          // allDay: true,
          // daysOfWeek: [],
        }
  );

  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState([]);

  useEffect(() => {}, [event]);

  const transformUserData = (data) => {
    return data.map((user, index) => ({
      key: user.user_id || `user_${index}`, // Provide a unique fallback key
      label: user.first_name + " " + user.last_name || "",
      role: user.role_names || "",
    }));
  };

  const userTaskMappings = [
    { role: "admin", types: ["event", "notification", "queue"] },
    { role: "regante", types: ["queue"] },
    { role: "tecnico", types: ["event", "notification"] },
  ];
  const filterUsersByRole = (type) => {
    return userData.filter((user) => {
      const userMapping = userTaskMappings.find((mapping) =>
        user.role.includes(mapping.role)
      );
      return userMapping && userMapping.types.includes(type);
    });
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://0movft1iw8.execute-api.eu-west-1.amazonaws.com/dev/users/manageUsers?action=getUsers"
      );
      if (response.data.body) {
        const responseBody = JSON.parse(response.data.body);
        const transformedData = transformUserData(responseBody);
        setUserData(transformedData);
      } else {
        console.error("No body in response");
      }
    } catch (error) {
      console.error("Error fetching data from AWS Lambda:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (event) {
      const start = event.start;

      if (event.extendedProps) {
        const end = event.end || new Date(event.extendedProps.endDate);
        console.log("event.extendedProps:", event.extendedProps);
        setLocalEvent({
          event_id: event.extendedProps.event_id,
          title: event.extendedProps.message || event.title,
          type: event.extendedProps.type || "event",
          start: start.toISOString().substring(0, 16),
          end: end.toISOString().substring(0, 16),
          // // status: event.extendedProps.status || "",
          urgency: event.extendedProps.urgency || "",
          assigned: event.extendedProps.assigned || "",
          parcela: event.extendedProps.parcela || "",
          // city:
          //   (event.extendedProps.location && event.extendedProps.location.city) ||
          //   "",
          // location:
          //   (event.extendedProps.location &&
          //     event.extendedProps.location.specificLocation) ||
          //   "",
          // coordinates:
          //   (event.extendedProps.location &&
          //     event.extendedProps.location.coordinates) ||
          //   [],
          recurrence: event.extendedProps.recurrence || false,
          // // allDay: event.extendedProps.allDay || true,
          // daysOfWeek: event.extendedProps.daysOfWeek || [],
        });
      } else {
        const end = event.end || new Date(event.endDate);

        console.log("event:", event);
        setLocalEvent({
          event_id: event.event_id,
          title: event.title,
          type: event.type || "event",
          start: start,
          end: end,
          // // status: event.extendedProps.status || "",
          urgency: event.urgency || "",
          assigned: event.assigned || "",
          parcela: event.parcela || "",
          // city:
          //   (event.extendedProps.location && event.extendedProps.location.city) ||
          //   "",
          // location:
          //   (event.extendedProps.location &&
          //     event.extendedProps.location.specificLocation) ||
          //   "",
          // coordinates:
          //   (event.extendedProps.location &&
          //     event.extendedProps.location.coordinates) ||
          //   [],
          recurrence: event.recurrence || false,
          // // allDay: event.extendedProps.allDay || true,
          // daysOfWeek: event.extendedProps.daysOfWeek || [],
        });
      }
    }
  }, [event]);

  const handleTaskChange = (e) => {
    const { name, value } = e.target;
    setLocalEvent((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleTitleChange = (value) => {
    setLocalEvent({
      ...localEvent,
      title: value,
    });
    /* console.log("AddTaskForm handleTitleChange Title selected:", value); */
    if (errors.title) {
      setErrors({ ...errors, title: null });
    }
  };

  // const handleTypeChange = () => {
  //   setLocalEvent((prevTask) => ({
  //     ...prevTask,
  //     type: value,
  //   }));
  // };

  const handleAssignedUserChange = (value) => {
    setLocalEvent({
      ...localEvent,
      assigned: value,
    });
  };

  const logUserFind = (assigned) => {
    // console.log("AddTaskForm Current users array in logUserFind:", userData);

    const foundUser = userData.find((u) => String(u.key) === assigned);
    // console.log("AddTaskForm task.assigned:", assigned);
    // console.log("AddTaskForm foundUser:", foundUser);
    return foundUser?.label || "Usuario no encontrado";
  };

  const logPriorityFind = (urgency) => {
    //console.log("AddTaskForm Current priorities array in logPriorityFind:", priorities);
    const foundPriority = priorities.find((p) => p.value === urgency);
    //console.log("AddTaskForm task.urgency:", urgency);
    //console.log("AddTaskForm foundPriority:", foundPriority);
    return foundPriority?.label || "Prioridad no encontrada";
  };

  const handleSelectChange = (value, field) => {
    setLocalEvent((prevTask) => ({
      ...prevTask,
      [field]: value,
    }));
  };

  const handlePriorityChange = (value) => {
    const numericValue = Number(value); // Ensure the value is a number
    setLocalEvent((prevTask) => {
      const updatedTask = { ...prevTask, urgency: numericValue };
      return updatedTask;
    });
    if (errors.urgency) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        urgency: null,
      }));
    }
    /* console.log(
      "AddTaskForm handlePriorityChange Priority selected:",
      numericValue
    ); */
  };

  const handleDateTimeChange = (dateTime, type) => {
    setLocalEvent({
      ...localEvent,
      [type]: dateTime,
    });
  };
  const validateForm = () => {
    const newErrors = {};
    if (!localEvent.title) newErrors.title = "El título es obligatorio.";
    if (!localEvent.urgency) newErrors.urgency = "La prioridad es obligatoria.";
    if (!localEvent.start)
      newErrors.start = "La hora de inicio es obligatoria.";
    if (!localEvent.end)
      newErrors.end = "La hora de finalización es obligatoria.";
    return newErrors;
  };

  const handleSave = (e) => {
    e.preventDefault();
    console.log("Handle Save Called");
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.log("Form validation failed:", newErrors);
    } else {
      const assignedArray = localEvent.assigned
        .split(",")
        .map((item) => item.trim());
      const updatedTask = {
        ...localEvent,
        assigned: assignedArray,
        event_id: localEvent.event_id,
      };

      if (onSave) {
        console.log("Saving task:", updatedTask);
        onSave(updatedTask);
      }
      setIsEditingEvent(false);

      onClose();
    }
  };

  // const handleCheckboxChange = () => {
  //   setLocalEvent((prevTask) => ({
  //     ...prevTask,
  //     recurrence: !prevTask.recurrence,
  //   }));
  // };

  // const handleDayOfWeekChange = (day) => {
  //   const scopedDaysOfWeek = [...localEvent.daysOfWeek];
  //   const dayIndex = scopedDaysOfWeek.indexOf(day);
  //   if (dayIndex === -1) {
  //     scopedDaysOfWeek.push(day); // Add day
  //   } else {
  //     scopedDaysOfWeek.splice(dayIndex, 1); // Remove day
  //   }
  //   setLocalEvent({ ...localEvent, daysOfWeek: scopedDaysOfWeek });
  // };

  // const daysOfWeek = [
  //   { value: 1, label: "Lu" },
  //   { value: 2, label: "Ma" },
  //   { value: 3, label: "Mi" },
  //   { value: 4, label: "Ju" },
  //   { value: 5, label: "Vi" },
  //   { value: 6, label: "Sa" },
  //   { value: 0, label: "Do" },
  // ];

  const priorities = [
    { value: 1, label: "Bajo" },
    { value: 2, label: "Medio" },
    { value: 3, label: "Alto" },
    { value: 4, label: "Urgente" },
    { value: 5, label: "Crítico" },
  ];

  if (!isOpen || !localEvent) return null;

  const startTime = new Date(localEvent.start);
  const endTime = new Date(localEvent.end);
  const formattedDateTimeRange = formatDateTimeRange(startTime, endTime);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        containerId={containerId}
        onClose={onClose}
        open={isOpen}
        className="absolute left-1/2 top-1/2"
      >
        <DialogHeader>
          <DialogTitle>
            {eventOptions.find((option) => option.value === localEvent.type)
              ?.label || "Evento"}
            :{" "}
            {maintenanceItemsWithLabels.find(
              (p) => p.value === localEvent.title
            )?.label || localEvent.title}
          </DialogTitle>
          <Label className="text-gray-800 dark:text-gray-200">
            {formattedDateTimeRange}
          </Label>
        </DialogHeader>
        {isEditMode ? (
          <form
            onSubmit={handleSave}
            className="grid grid-cols-[auto,_minmax(0,1fr)] [&_label]:pt-2 gap-2"
          >
            {/* <Label htmlFor="type">Tipo</Label>
            <Select
              id="type"
              className="rounded-md border mt-2"
              value={localEvent.type}
              onValueChange={handleSelectChange}>
              <SelectTrigger className="h-8">
                <SelectValue placeholder="Selecciona típo">
                  {localEvent.type
                    ? eventOptions.find((p) => p.value === localEvent.type)?.label
                    : "Selecciona típo"}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {eventOptions.map((eventType) => (
                  <SelectItem
                    key={`event_${eventType.value}`}
                    value={eventType.value}>
                    {eventType.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select> */}
            <Label htmlFor="type">Tipo</Label>
            <Select
              id="type"
              className="rounded-md border mt-2"
              value={localEvent.type}
              onValueChange={(value) => handleSelectChange(value, "type")}
            >
              <SelectTrigger className="h-8">
                <SelectValue placeholder="Selecciona tipo">
                  {localEvent.type
                    ? eventOptions.find((p) => p.value === localEvent.type)
                        ?.label
                    : "Selecciona tipo"}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {eventOptions.map((eventType) => (
                  <SelectItem key={eventType.value} value={eventType.value}>
                    {eventType.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Label htmlFor="title">Titulo</Label>
            <div className="flex flex-col">
              {localEvent.type &&
              localEvent.type !== "" &&
              localEvent.type !== "event" ? (
                <>
                  <Input
                    id="title"
                    name="title"
                    className="h-8"
                    placeholder="E.g. Riego de Agua"
                    value={localEvent.title}
                    onChange={handleTaskChange}
                  />
                </>
              ) : (
                <>
                  <Select
                    id="title"
                    className="rounded-md border mt-2"
                    value={localEvent.title}
                    onValueChange={handleTitleChange}
                  >
                    <SelectTrigger className="h-8">
                      <SelectValue placeholder="Selecciona Título">
                        {localEvent.title
                          ? maintenanceItemsWithLabels.find(
                              (p) => p.value === localEvent.title
                            )?.label
                          : "Selecciona Título"}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {maintenanceItemsWithLabels.map((maintenanceItem) => (
                        <SelectItem
                          key={`maintenance_${maintenanceItem.value}`}
                          value={maintenanceItem.value}
                        >
                          {maintenanceItem.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </>
              )}
              {errors.title && (
                <p className="text-red-500 text-sm mr-auto">{errors.title}</p>
              )}
            </div>
            {/* <Input
                id="title"
                name="title"
                placeholder="E.g. Riego de Agua"
                value={localEvent.title}
                onChange={handleTaskChange}
              /> */}

            <Label htmlFor="urgency">Prioridad</Label>
            <div className="flex-col">
              <Select
                id="urgency"
                className="rounded-md border mt-2"
                value={localEvent.urgency}
                onValueChange={handlePriorityChange}
              >
                <SelectTrigger className="h-8">
                  <SelectValue placeholder="Selecciona prioridad">
                    {localEvent.urgency
                      ? priorities.find((p) => p.value === localEvent.urgency)
                          ?.label
                      : "Selecciona prioridad"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {priorities.map((urgency) => (
                    <SelectItem key={urgency.value} value={urgency.value}>
                      {urgency.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {errors.urgency && (
                <p className="text-red-500 text-sm mr-auto">{errors.urgency}</p>
              )}
            </div>

            <Label htmlFor="assigned">Asignar Usuario</Label>
            <Select
              id="assigned"
              className="rounded-md border mt-2"
              value={localEvent.assigned}
              onValueChange={handleAssignedUserChange}
            >
              <SelectTrigger className="h-8">
                <SelectValue placeholder="Selecciona usuario">
                  {localEvent.assigned
                    ? logUserFind(localEvent.assigned)
                    : "Selecciona usuario"}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Ninguno">Ninguno</SelectItem>
                {filterUsersByRole(localEvent.type).map((user) => (
                  <SelectItem key={user.key} value={user.key}>
                    {user.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* <Label htmlFor="assigned">Asignar</Label>
              <Input
                id="assigned"
                name="assigned"
                placeholder="Asignar usuario"
                value={localEvent.assigned}
                onChange={handleTaskChange}
              /> */}

            {/* // TODO: LATER ADD RECURRING EVENTS */}
            {/* <Label htmlFor="recurrence" className="h-8">
                Repetir
              </Label>
              <div className="flex !items-start ">
                <Checkbox
                  className="my-auto"
                  id="recurrence"
                  name="recurrence"
                  checked={localEvent.recurrence}
                  onCheckedChange={handleCheckboxChange}
                />
                {localEvent.recurrence && (
                  <ButtonGroup className="flex ml-auto justify-center">
                    {daysOfWeek.map((day, index) => (
                      <Button
                        key={day.value}
                        className={`text-sm h-8 py-0 px-1.5 ${
                          index === 0
                            ? "rounded-r-none border border-r"
                            : index === daysOfWeek.length - 1
                            ? "rounded-l-none border border-l-0 "
                            : "rounded-none border border-l-0 border-r"
                        } ${
                          localEvent.daysOfWeek.includes(day.value)
                            ? "dark:text-black"
                            : "dark:text-white"
                        } `}
                        variant={
                          localEvent.daysOfWeek.includes(day.value)
                            ? "default"
                            : "outline"
                        }
                        onClick={() => handleDayOfWeekChange(day.value)}>
                        {day.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                )}
              </div> */}

            <Label htmlFor="start">Fecha Inicio</Label>

            <div className="flex-col">
              <DateTimePickerForm
                date={localEvent.start}
                onDateTimeChange={(dateTime) =>
                  handleDateTimeChange(dateTime, "start")
                }
                type="start"
              />
              {errors.start && (
                <p className="text-red-500 text-sm mr-auto">{errors.start}</p>
              )}
            </div>
            <Label htmlFor="end">Fecha Fin</Label>
            <div className="flex-col">
              <DateTimePickerForm
                date={localEvent.end}
                onDateTimeChange={(dateTime) =>
                  handleDateTimeChange(dateTime, "end")
                }
                type="end"
              />
              {errors.end && (
                <p className="text-red-500 text-sm mr-auto">{errors.end}</p>
              )}
            </div>
          </form>
        ) : (
          <DialogDescription className="grid gap-y-1 gap-x-2 grid-cols-[auto,_1fr]">
            {/* <span className="dark:text-white">Titulo:</span>
            <span>{localEvent.title}</span> */}
            <span className="dark:text-white">Prioridad:</span>
            <span>
              {priorities.find((p) => p.value === localEvent.urgency)?.label}
            </span>
            <span className="dark:text-white">Tipo:</span>
            <span>
              {eventOptions.find((p) => p.value === localEvent.type)?.label}
            </span>
            <span className="dark:text-white">Asignado:</span>
            <span>{localEvent.assigned}</span>
            {localEvent.city && (
              <>
                <span className="dark:text-white">Ciudad:</span>
                <span>{localEvent.city}</span>
              </>
            )}
            {localEvent.location && (
              <>
                <span className="dark:text-white">Ubicación:</span>
                <span>{localEvent.location}</span>
              </>
            )}
            {localEvent.parcela && (
              <>
                <span className="dark:text-white">Parcela:</span>
                <span>{localEvent.parcela}</span>
              </>
            )}
            {/* {localEvent.coordinates.length > 0 && (
              <>
                <span className="dark:text-white">Coordenadas:</span>
                <span>{localEvent.coordinates.join(", ")}</span>
              </>
            )} */}
          </DialogDescription>
        )}
        <DialogFooter className="sm:justify-start">
          {isEditMode ? (
            <div className="flex justify-between w-full">
              <Button
                onClick={() => setIsEditMode(!isEditMode)}
                type="button"
                variant="secondary"
              >
                Cancelar
              </Button>
              <Button variant="positive" type="submit" onClick={handleSave}>
                Guardar
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                setIsEditMode(!isEditMode);
                setIsEditingEvent(true);
              }}
              variant="secondary"
            >
              {isEditMode ? "Guardar" : "Editar"}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CustomPopover;
