
/**
 * Model component that loads and displays a 3D model using react-three-fiber and drei.
 * 
 * @param {JSX.IntrinsicElements['group']} props - The properties passed to the group element.
 * @returns {JSX.Element} The rendered 3D model.
 * 
 * @typedef {Object} GLTFResult
 * @property {Object} nodes - The nodes of the GLTF model.
 * @property {THREE.Mesh} nodes.Mesh_0001 - The mesh node of the model.
 * @property {Object} materials - The materials of the GLTF model.
 * @property {THREE.MeshStandardMaterial} materials['Material_0.001'] - The material of the model.
 * 
 * @example
 * // Usage example:
 * <Canvas>
 *   <Model />
 * </Canvas>
 */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { Stats } from "@react-three/drei";
import { useRef, useState, useEffect } from "react";
import * as THREE from 'three'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

export function Model(props) {
  
  const { nodes, materials } = useGLTF('/AcequiaBAJA.gltf') 
  const ref = useRef(null)
  const [direction, setDirection] = useState(1); // 1 for up, -1 for down
  const speed = 0.02; // Speed of the animation
  const maxY = 0; // Maximum Y position
  const minY = -14; // Minimum Y position
  const rotationSpeed = Math.PI / 500; // Rotation speed
  const [isRotating, setIsRotating] = useState(false); // State to handle rotation

  // useFrame(() => {
  //   if (ref.current) {
  //     if (isRotating) {
  //       // Rotate 180 degrees
  //       ref.current.rotation.z += rotationSpeed;
  //       if (ref.current.rotation.z >= Math.PI || ref.current.rotation.z <= -Math.PI) {
  //         ref.current.rotation.z = 0; // Reset rotation
  //         setIsRotating(false); // Stop rotating
  //         setDirection(-direction); // Reverse direction
  //       }
  //     } else {
  //       // Update Y position
  //       ref.current.position.y += direction * speed;
  //       // Check bounds and switch direction
  //       if (ref.current.position.y >= maxY ) {
  //         setIsRotating(true); // Start rotating
  //       }
  //     }
  //   }
  // });

  return (
    <group ref={ref} position={[0, minY, 3]} {...props} dispose={null}>
      {/* <perspectiveCamera  /> */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mesh_0001.geometry}
        material={materials['Material_0.001']}
      />
    </group>
  )
}

useGLTF.preload('/AcequiaBAJA.gltf')